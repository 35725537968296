<script>
  import { getPreferredTheme } from './util';
  import { PROJECTS } from './data';
  import { COLOR_SCHEMES } from "./constants";

  import Nav from './components/nav.svelte';
  import Hero from './components/hero.svelte';
  import Project from './components/project.svelte';

  let theme;
  getPreferredTheme((userTheme) => theme = userTheme);

  $: colorScheme = COLOR_SCHEMES[theme];
</script>

<style>:root {
  --siteBg: white;
  --siteBgDark: #fafafa;
  --textColor: black;
  --textColorHover: #4a4a4a;
  --textColorMuted: #737373;
  --fontFamily: "SF Mono", "SFMono-Regular", "Roboto Mono", "Menlo", "Monaco",
    "Consolas", sans-serif;
  --fontFamilyBold: "SF Mono", "SFMono-Semibold", "Roboto Mono", "Menlo",
    "Consolas", sans-serif;
  --fontFamilySansSerif: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
}

:global(*) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: var(--fontFamily);
  text-decoration: none;
  color: inherit;
}

:global(html) {
  font-size: 16px;
  background: var(--siteBg);
  color: var(--textColor);
}

:global(strong) {
  font-family: var(--fontFamilyBold);
  font-weight: 600;
}

:global(.particle) {
  animation: particle ease 5s infinite alternate;
}

@keyframes particle {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }
  to {
    opacity: 0.5;
    transform: translateY(10px);
  }
}

@keyframes light {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes glowFilter {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

@media (max-width: 60rem) {
  :global(html) {
    font-size: 14px;
  }

  @keyframes particle {
    from {
      opacity: 1;
      transform: translateY(-5px);
    }
    to {
      opacity: 0.5;
      transform: translateY(5px);
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --siteBg: #33332c;
    --siteBgDark: #272822;
    --textColor: wheat;
    --textColorHover: darkgoldenrod;
    --textColorMuted: #6e6f67;
  }
}
</style>

<Nav />
<Hero />

<div id="work">
  {#each PROJECTS as project}
    <Project {...project} />
  {/each}
</div>

<svg width="0" height="0" style="position: absolute">
  <defs>
    <linearGradient id="safariBar" x1="0" y1="0" y2="1">
      <stop offset="0" stop-color={colorScheme.bar.top} />
      <stop offset="1" stop-color={colorScheme.bar.bottom} />
    </linearGradient>
    <linearGradient id="safariButton" x1="0" y1="0" y2="1">
      <stop offset="0" stop-color={colorScheme.button.top} />
      <stop offset="1" stop-color={colorScheme.button.bottom} />
    </linearGradient>
  </defs>
</svg>
